import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { PartialRouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Dashboards

const Dashboard = Loader(lazy(() => import('src/content/dashboards/Main')));

// Workers

const WorkerManagement = Loader(lazy(() => import('src/content/applications/WorkerManagement')));
const WorkerSearch = Loader(lazy(() => import('src/content/applications/WorkerSearch')));


// Misc
const NotFound = Loader(lazy(() => import('src/content/pages/Status/Status404')));

// End

const routes: PartialRouteObject[] = [
  {
    path: '*',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to={"/dashboard"} replace />
      },
      {
        path: '*',
        element: <NotFound />
      },
    ]
  },
  {
    path: 'dashboard',
    element: (
      <SidebarLayout />
    ),
    children: [
      {
        path: '/',
        element: <Dashboard />
      },
    ]
  },
  {
    path: 'workers',
    element: (
      <SidebarLayout />
    ),
    children: [
      {
        path: '/',
        element: <WorkerSearch />,
      },
      {
        path: "/:workerId",
        element: <WorkerManagement />,
      },
    ]
  },
];

export default routes;
