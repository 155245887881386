import React, { FC } from 'react';
import { Box, Container, Card, Typography, TextField, Button, Alert } from '@mui/material';
import { Helmet } from 'react-helmet-async';

import { styled } from '@mui/material/styles';

import logoSrc from "../../logo.png";

const LoginFormWrapper = styled(Box)(
  () => `
    overflow: auto;
    flex: 1;
    overflow-x: hidden;
    align-items: center;
`
);

type loginHandler = (email: string, password: string) => Promise<void>;

type LoginFormProps = {
  doAuth: loginHandler;
};

const LoginForm: FC<LoginFormProps> = ({
  doAuth,
}) => {
  const [processing, setProcessing] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");
  const [emailAddress, setEmailAddress] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");

  const submit = () => {
    setProcessing(true);
    doAuth(emailAddress, password)
      .catch(msg => setError(msg))
      .finally(() => setProcessing(false));
  };

  const submitIfEnter = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      submit();
    }
  };

  return (
    <LoginFormWrapper>
      <Helmet>
        <title>StaffAtlas Facts :: Facts Login</title>
      </Helmet>
      <Container maxWidth="sm">
        <Card sx={{ my: 1, p: 10, mb: 10, borderRadius: 1 }}>
          <img src={logoSrc} alt="StaffAtlas Facts" />
          <Typography variant="h1" sx={{mt: 2}}>
            Consultant Sign in
          </Typography>
          <Typography variant="subtitle1" sx={{mt: 2}}>
            Provide your StaffAtlas Facts e-mail address and password to sign into your account.
          </Typography>

          { error.length > 0 ? <Alert severity="warning" sx={{mt: 1}}>{error}</Alert> : null }

          <TextField
            id="email"
            label="Email address"
            variant="outlined"
            value={emailAddress}
            onChange={(e) => {setEmailAddress(e.target.value)}}
            onKeyDown={submitIfEnter}
            sx={{mt: 2}}
            disabled={processing}
            fullWidth
          />

          <TextField
            id="password"
            label="Password"
            variant="outlined"
            type="password"
            value={password}
            onChange={(e) => {setPassword(e.target.value)}}
            onKeyDown={submitIfEnter}
            sx={{mt: 2}}
            disabled={processing}
            fullWidth
          />

          <Button
            variant="contained"
            sx={{mt: 2}}
            disabled={processing}
            onClick={submit}
            fullWidth
          >Login</Button>

        </Card>
      </Container>
    </LoginFormWrapper>
  );
}

export default LoginForm;
