import Cookie from "js-cookie";

import { API_BASE } from "../constants";

export type Token = {
  token: string;
};

export type LoginResponse = {
  token: string;
  user: {
    forename: string;
    surname: string;
    avatar: string;
  };
};

export type Profile = {
  forename: string;
  surname: string;
}

export async function getProfile(token: string): Promise<Profile> {
  try {
    const res = await fetch(`${API_BASE}/api/employees/me`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      }
    });

    return res.json();
  } catch (err) {
    throw new Error(`${err}`);
  }
}

export async function loginWithCredentials(e: string, p: string): Promise<LoginResponse> {
  try {
    const res = await fetch(`${API_BASE}/api/auth/login`, {
      method: "POST",
      body: JSON.stringify({
        username: e,
        password: p,
      }),
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      }
    });

    if (!res.ok) {
      throw new Error(`invalid credentials`);
    }

    return res.json();
  } catch (err) {
    throw new Error(`${err}`);
  }
}

/**
 * Store auth credentials in a cookie.
 * @param auth the response from login api to store
 * @param expiry number of days until expiry or Date
 */
export function storeAuthInCookie(
  token: string,
): void {
  Cookie.set("sid", JSON.stringify({ token }), {
    secure: true,
    sameSite: "strict",
    expires: new Date(new Date().getTime() + 25200 * 1000),
  });
}

export function removeAuthFromCookie(): void {
  Cookie.remove("sid");
}

/**
 * Get auth credentials.
 */
export function getAuthFromCookie(): Token | void {
  const raw = Cookie.get("sid");

  if (raw) {
    return JSON.parse(raw);
  }
}

/**
 * Get the token from stored auth.
 */
export function getTokenFromCookie(): string | null {
  const auth = getAuthFromCookie();

  if (auth) {
    return auth.token;
  }

  return null;
}

export function storeEmailInCookie(email: string): void {
  Cookie.set("email", email, {
    secure: true,
    sameSite: "strict",
    expires: 90,
  });
}

export function getEmailFromCookie(): string | undefined {
  return Cookie.get("email");
}

export function removeEmailFromCookie(): void {
  Cookie.remove("email");
}

export function getDecodedToken(token: string): string {
  return atob(token.split(".")[1]);
}
