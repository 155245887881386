import { useRoutes } from 'react-router-dom';
import routes from './router';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import ThemeProvider from './theme/ThemeProvider';
import { CssBaseline } from '@mui/material';

import { UserContext, UserProvider } from './contexts/UserContext';
import { FC, useContext, useEffect, useState } from 'react';

import {
  getTokenFromCookie,
  loginWithCredentials,
  storeAuthInCookie,
  storeEmailInCookie,
  removeAuthFromCookie,
  getProfile,
} from "./auth/auth";
import { LoginForm } from "./content/auth";

enum UserGateState {
  CHECKING_TOKEN,
  REQUIRE_LOGIN,
  AUTHORIZED
}

const UserGate: FC = ({ children }) => {
  const [ugs, setUGS] = useState<UserGateState>(UserGateState.CHECKING_TOKEN);
  const [c, setC] = useState<React.ReactElement>(null);
  const userCtx = useContext(UserContext);

  useEffect(() => {
    if (ugs === UserGateState.AUTHORIZED) {
      if (!userCtx.token) { // logout request
        removeAuthFromCookie();
        setUGS(UserGateState.REQUIRE_LOGIN);
        return;
      }

      setC(children as React.ReactElement);
      return;
    }

    switch (ugs) {
      case UserGateState.CHECKING_TOKEN:
        const token = getTokenFromCookie();

        if (!token) {
          setUGS(UserGateState.REQUIRE_LOGIN);
          return;
        }

        getProfile(token)
          .then(res => {
            userCtx.handlers.store({
              ...res,
            }, token);
            setUGS(UserGateState.AUTHORIZED);
          })
          .catch(() => {
            removeAuthFromCookie();
            setUGS(UserGateState.REQUIRE_LOGIN);
          });
        break;
      case UserGateState.REQUIRE_LOGIN:
        const doAuth = (e: string, p: string) => {
          return new Promise<void>((_, r) => {
            loginWithCredentials(e, p)
            .then(data => {
              storeEmailInCookie(e);
              storeAuthInCookie(data.token);

              userCtx.handlers.store({
                ...data.user,
              }, data.token);

              setUGS(UserGateState.AUTHORIZED);
            })
            .catch(err => {
              r(`${err}`);
            });
          });
        };

        setC(<LoginForm
          doAuth={doAuth}
        />);
        break;
    }
  }, [ugs, children, userCtx]);

  return c;
};

const App = () => {
  const content = useRoutes(routes);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <UserProvider>
          <UserGate>
            {content}
          </UserGate>
        </UserProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
