export const API_BASE = process.env["REACT_APP_API_URI"] || "https://api.gtrrecruitment.com";


export const GENDERS = [
  {value: 'male', label: 'Male'},
  {value: 'female', label: 'Female'},
];

export const TITLES = [
  {value: 'mr', label: 'Mr'},
  {value: 'miss', label: 'Miss'},
  {value: 'mrs', label: 'Mrs'},
  {value: 'dr', label: 'Dr'},
];

export const NATIONALITIES = [
  {value: 'Afghan', label: 'Afghan'},
  {value: 'Albanian', label: 'Albanian'},
  {value: 'Algerian', label: 'Algerian'},
  {value: 'American', label: 'American'},
  {value: 'Andorran', label: 'Andorran'},
  {value: 'Angolan', label: 'Angolan'},
  {value: 'Antiguans', label: 'Antiguans'},
  {value: 'Argentinean', label: 'Argentinean'},
  {value: 'Armenian', label: 'Armenian'},
  {value: 'Australian', label: 'Australian'},
  {value: 'Austrian', label: 'Austrian'},
  {value: 'Azerbaijani', label: 'Azerbaijani'},
  {value: 'Bahamian', label: 'Bahamian'},
  {value: 'Bahraini', label: 'Bahraini'},
  {value: 'Bangladeshi', label: 'Bangladeshi'},
  {value: 'Barbadian', label: 'Barbadian'},
  {value: 'Barbudans', label: 'Barbudans'},
  {value: 'Batswana', label: 'Batswana'},
  {value: 'Belarusian', label: 'Belarusian'},
  {value: 'Belgian', label: 'Belgian'},
  {value: 'Belizean', label: 'Belizean'},
  {value: 'Beninese', label: 'Beninese'},
  {value: 'Bhutanese', label: 'Bhutanese'},
  {value: 'Bolivian', label: 'Bolivian'},
  {value: 'Bosnian', label: 'Bosnian'},
  {value: 'Brazilian', label: 'Brazilian'},
  {value: 'British', label: 'British'},
  {value: 'Bruneian', label: 'Bruneian'},
  {value: 'Bulgarian', label: 'Bulgarian'},
  {value: 'Burkinabe', label: 'Burkinabe'},
  {value: 'Burmese', label: 'Burmese'},
  {value: 'Burundian', label: 'Burundian'},
  {value: 'Cambodian', label: 'Cambodian'},
  {value: 'Cameroonian', label: 'Cameroonian'},
  {value: 'Canadian', label: 'Canadian'},
  {value: 'Cape Verdean', label: 'Cape Verdean'},
  {value: 'Central African', label: 'Central African'},
  {value: 'Chadian', label: 'Chadian'},
  {value: 'Chilean', label: 'Chilean'},
  {value: 'Chinese', label: 'Chinese'},
  {value: 'Colombian', label: 'Colombian'},
  {value: 'Comoran', label: 'Comoran'},
  {value: 'Congolese', label: 'Congolese'},
  {value: 'Congolese', label: 'Congolese'},
  {value: 'Costa Rican', label: 'Costa Rican'},
  {value: 'Croatian', label: 'Croatian'},
  {value: 'Cuban', label: 'Cuban'},
  {value: 'Cypriot', label: 'Cypriot'},
  {value: 'Czech', label: 'Czech'},
  {value: 'Danish', label: 'Danish'},
  {value: 'Djibouti', label: 'Djibouti'},
  {value: 'Dominican', label: 'Dominican'},
  {value: 'Dominican', label: 'Dominican'},
  {value: 'Dutch', label: 'Dutch'},
  {value: 'Dutchman', label: 'Dutchman'},
  {value: 'Dutchwoman', label: 'Dutchwoman'},
  {value: 'East Timorese', label: 'East Timorese'},
  {value: 'Ecuadorean', label: 'Ecuadorean'},
  {value: 'Egyptian', label: 'Egyptian'},
  {value: 'Emirian', label: 'Emirian'},
  {value: 'Equatorial Guinean', label: 'Equatorial Guinean'},
  {value: 'Eritrean', label: 'Eritrean'},
  {value: 'Estonian', label: 'Estonian'},
  {value: 'Ethiopian', label: 'Ethiopian'},
  {value: 'Fijian', label: 'Fijian'},
  {value: 'Filipino', label: 'Filipino'},
  {value: 'Finnish', label: 'Finnish'},
  {value: 'French', label: 'French'},
  {value: 'Gabonese', label: 'Gabonese'},
  {value: 'Gambian', label: 'Gambian'},
  {value: 'Georgian', label: 'Georgian'},
  {value: 'German', label: 'German'},
  {value: 'Ghanaian', label: 'Ghanaian'},
  {value: 'Greek', label: 'Greek'},
  {value: 'Grenadian', label: 'Grenadian'},
  {value: 'Guatemalan', label: 'Guatemalan'},
  {value: 'Guinea-Bissauan', label: 'Guinea-Bissauan'},
  {value: 'Guinean', label: 'Guinean'},
  {value: 'Guyanese', label: 'Guyanese'},
  {value: 'Haitian', label: 'Haitian'},
  {value: 'Herzegovinian', label: 'Herzegovinian'},
  {value: 'Honduran', label: 'Honduran'},
  {value: 'Hungarian', label: 'Hungarian'},
  {value: 'I-Kiribati', label: 'I-Kiribati'},
  {value: 'Icelander', label: 'Icelander'},
  {value: 'Indian', label: 'Indian'},
  {value: 'Indonesian', label: 'Indonesian'},
  {value: 'Iranian', label: 'Iranian'},
  {value: 'Iraqi', label: 'Iraqi'},
  {value: 'Irish', label: 'Irish'},
  {value: 'Irish', label: 'Irish'},
  {value: 'Israeli', label: 'Israeli'},
  {value: 'Italian', label: 'Italian'},
  {value: 'Ivorian', label: 'Ivorian'},
  {value: 'Jamaican', label: 'Jamaican'},
  {value: 'Japanese', label: 'Japanese'},
  {value: 'Jordanian', label: 'Jordanian'},
  {value: 'Kazakhstani', label: 'Kazakhstani'},
  {value: 'Kenyan', label: 'Kenyan'},
  {value: 'Kittian and Nevisian', label: 'Kittian and Nevisian'},
  {value: 'Kuwaiti', label: 'Kuwaiti'},
  {value: 'Kyrgyz', label: 'Kyrgyz'},
  {value: 'Laotian', label: 'Laotian'},
  {value: 'Latvian', label: 'Latvian'},
  {value: 'Lebanese', label: 'Lebanese'},
  {value: 'Liberian', label: 'Liberian'},
  {value: 'Libyan', label: 'Libyan'},
  {value: 'Liechtensteiner', label: 'Liechtensteiner'},
  {value: 'Lithuanian', label: 'Lithuanian'},
  {value: 'Luxembourger', label: 'Luxembourger'},
  {value: 'Macedonian', label: 'Macedonian'},
  {value: 'Malagasy', label: 'Malagasy'},
  {value: 'Malawian', label: 'Malawian'},
  {value: 'Malaysian', label: 'Malaysian'},
  {value: 'Maldivan', label: 'Maldivan'},
  {value: 'Malian', label: 'Malian'},
  {value: 'Maltese', label: 'Maltese'},
  {value: 'Marshallese', label: 'Marshallese'},
  {value: 'Mauritanian', label: 'Mauritanian'},
  {value: 'Mauritian', label: 'Mauritian'},
  {value: 'Mexican', label: 'Mexican'},
  {value: 'Micronesian', label: 'Micronesian'},
  {value: 'Moldovan', label: 'Moldovan'},
  {value: 'Monacan', label: 'Monacan'},
  {value: 'Mongolian', label: 'Mongolian'},
  {value: 'Moroccan', label: 'Moroccan'},
  {value: 'Mosotho', label: 'Mosotho'},
  {value: 'Motswana', label: 'Motswana'},
  {value: 'Mozambican', label: 'Mozambican'},
  {value: 'Namibian', label: 'Namibian'},
  {value: 'Nauruan', label: 'Nauruan'},
  {value: 'Nepalese', label: 'Nepalese'},
  {value: 'Netherlander', label: 'Netherlander'},
  {value: 'New Zealander', label: 'New Zealander'},
  {value: 'Ni-Vanuatu', label: 'Ni-Vanuatu'},
  {value: 'Nicaraguan', label: 'Nicaraguan'},
  {value: 'Nigerian', label: 'Nigerian'},
  {value: 'Nigerien', label: 'Nigerien'},
  {value: 'North Korean', label: 'North Korean'},
  {value: 'Northern Irish', label: 'Northern Irish'},
  {value: 'Norwegian', label: 'Norwegian'},
  {value: 'Omani', label: 'Omani'},
  {value: 'Pakistani', label: 'Pakistani'},
  {value: 'Palauan', label: 'Palauan'},
  {value: 'Panamanian', label: 'Panamanian'},
  {value: 'Papua New Guinean', label: 'Papua New Guinean'},
  {value: 'Paraguayan', label: 'Paraguayan'},
  {value: 'Peruvian', label: 'Peruvian'},
  {value: 'Polish', label: 'Polish'},
  {value: 'Portuguese', label: 'Portuguese'},
  {value: 'Qatari', label: 'Qatari'},
  {value: 'Romanian', label: 'Romanian'},
  {value: 'Russian', label: 'Russian'},
  {value: 'Rwandan', label: 'Rwandan'},
  {value: 'Saint Lucian', label: 'Saint Lucian'},
  {value: 'Salvadoran', label: 'Salvadoran'},
  {value: 'Samoan', label: 'Samoan'},
  {value: 'San Marinese', label: 'San Marinese'},
  {value: 'Sao Tomean', label: 'Sao Tomean'},
  {value: 'Saudi', label: 'Saudi'},
  {value: 'Scottish', label: 'Scottish'},
  {value: 'Senegalese', label: 'Senegalese'},
  {value: 'Serbian', label: 'Serbian'},
  {value: 'Seychellois', label: 'Seychellois'},
  {value: 'Sierra Leonean', label: 'Sierra Leonean'},
  {value: 'Singaporean', label: 'Singaporean'},
  {value: 'Slovakian', label: 'Slovakian'},
  {value: 'Slovenian', label: 'Slovenian'},
  {value: 'Solomon Islander', label: 'Solomon Islander'},
  {value: 'Somali', label: 'Somali'},
  {value: 'South African', label: 'South African'},
  {value: 'South Korean', label: 'South Korean'},
  {value: 'Spanish', label: 'Spanish'},
  {value: 'Sri Lankan', label: 'Sri Lankan'},
  {value: 'Sudanese', label: 'Sudanese'},
  {value: 'Surinamer', label: 'Surinamer'},
  {value: 'Swazi', label: 'Swazi'},
  {value: 'Swedish', label: 'Swedish'},
  {value: 'Swiss', label: 'Swiss'},
  {value: 'Syrian', label: 'Syrian'},
  {value: 'Taiwanese', label: 'Taiwanese'},
  {value: 'Tajik', label: 'Tajik'},
  {value: 'Tanzanian', label: 'Tanzanian'},
  {value: 'Thai', label: 'Thai'},
  {value: 'Togolese', label: 'Togolese'},
  {value: 'Tongan', label: 'Tongan'},
  {value: 'Trinidadian or Tobagonian', label: 'Trinidadian or Tobagonian'},
  {value: 'Tunisian', label: 'Tunisian'},
  {value: 'Turkish', label: 'Turkish'},
  {value: 'Tuvaluan', label: 'Tuvaluan'},
  {value: 'Ugandan', label: 'Ugandan'},
  {value: 'Ukrainian', label: 'Ukrainian'},
  {value: 'Uruguayan', label: 'Uruguayan'},
  {value: 'Uzbekistani', label: 'Uzbekistani'},
  {value: 'Venezuelan', label: 'Venezuelan'},
  {value: 'Vietnamese', label: 'Vietnamese'},
  {value: 'Welsh', label: 'Welsh'},
  {value: 'Welsh', label: 'Welsh'},
  {value: 'Yemenite', label: 'Yemenite'},
  {value: 'Zambian', label: 'Zambian'},
  {value: 'Zimbabwean', label: 'Zimbabwean'},
];

export const CONTRACT_TYPE = [
  {value: 'payee', label: 'PAYEE'},
  {value: 'ltd_company', label: 'Limited Company'},
];

export const WORK_TYPES = [
  {value: 'driving', label: 'Driving'},
  // {value: 'industrial', label: 'Industrial'},
  {value: 'warehouse', label: 'Warehouse'},
  {value: 'forklift', label: 'Forklift'},
];

export const SKILLS_GROUPED = {
  'warehouse': [
    {value: 'warehouse_op', label: 'Warehouse Op'},
    {value: 'packing', label: 'Picker/Packer'},
    {value: 'management', label: 'Management'},
    {value: 'team_leader', label: 'Team Leader'},
    {value: 'social_caring', label: 'Social/Caring'},
    {value: 'labourer', label: 'Labourer'},
    {value: 'parcel_sorting', label: 'Parcel Sorting'},
    {value: 'stores_goods_in_out', label: 'Goods In/Out'},
    {value: 'food_production', label: 'Food Production'},
    {value: 'administration', label: 'Administration'},
    {value: 'supervisor', label: 'Supervisor'},
    {value: 'quality_control', label: 'Quality Control'},
    {value: 'sewing_machinist', label: 'Sewing Machinist'},
    {value: 'light_assembly', label: 'Light Assembly'},
    {value: 'heavy_assembly', label: 'Heavy Assembly'},
    {value: 'mail_sorter', label: 'Mail Sorter'},
    {value: 'printer_finisher', label: 'Printer Finisher'},
    {value: 'machine_operator', label: 'Machine Operator'},
    {value: 'cleaning', label: 'Cleaning'},
    {value: 'catering', label: 'Catering'},
    {value: 'injection_moulding', label: 'Injection Moulding'},
    {value: 'recycling', label: 'Recycling'},
    {value: 'food_bakery', label: 'Food Bakery'},
    {value: 'engineering', label: 'Engineering'},
    {value: 'wireman', label: 'Wireman'},
    {value: 'joiner', label: 'Joiner'},
    {value: 'bench_and_joiner', label: 'Bench &amp; Joiner'},
    {value: 'carpenter', label: 'Carpenter'},
    {value: 'welder', label: 'Welder'},
    {value: 'construction', label: 'Construction'},
    {value: 'office_general', label: 'Office/General'},
    {value: 'warehouse', label: 'Any Warehouse'},
    {value: 'saw_operator', label: 'Saw Operator'},
    {value: 'sheet_metal_worker', label: 'Sheet Metal Worker'},
    {value: 'customer_services', label: 'Stores Assistant'},
  ],
  'forklift': [
    {value: 'forklift_ppt', label: 'PPT'},
    {value: 'forklift_counter_balance', label: 'Counter Balance'},
    {value: 'forklift_reach', label: 'Reach'},
    {value: 'forklift_bendi_flexi', label: 'Bendi/Flexi'},
    {value: 'forklift_vna', label: 'VNA'},
  ],
  'driving': [
    {value: 'driving_class_one', label: 'Class 1 (C+E)'},
    {value: 'driving_class_two', label: 'Class 2 (C)'},
    {value: 'driving_seven_five_tonne', label: '7.5t/C1'},
    {value: 'van_driving', label: '3.5t/Van'},
    {value: 'driving_adr', label: 'ADR'},
    {value: 'driving_double_decker', label: 'Double Decker'},
    {value: 'driving_long_trailer', label: 'Long Trailer'},
    {value: 'driving_terberg_shunt', label: 'Terberg/Shunt'},
    {value: 'driving_wagon_and_drag', label: 'Wagon and Drag'},
    {value: 'driving_moffett', label: 'Moffett'},
    {value: 'driving_hiab', label: 'Hiab'},
    {value: 'drivers_mate', label: 'Drivers Mate'},
  ],
};

export const SKILLS = [
  ...SKILLS_GROUPED['warehouse'],
  ...SKILLS_GROUPED['forklift'],
  ...SKILLS_GROUPED['driving'],
];

export const WORKER_STATUSES = [
  {value: "active", label: "Active"},
  {value: "pending", label: "Pending"},
];
