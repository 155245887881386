import { ReactNode } from 'react';

import ManageSearchTwoToneIcon from '@mui/icons-material/ManageSearchTwoTone';
import RecentActorsTwoToneIcon from '@mui/icons-material/RecentActorsTwoTone';
import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
import EmojiEventsTwoToneIcon from '@mui/icons-material/EmojiEventsTwoTone';
import VerifiedUserTwoToneIcon from '@mui/icons-material/VerifiedUserTwoTone';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: '',
    items: [
      {
        name: 'Dashboard',
        link: '/dashboard',
        icon: TableChartTwoToneIcon
      }
    ]
  },
  {
    heading: 'Workers',
    items: [
      {
        name: 'Search',
        link: '/workers',
        icon: ManageSearchTwoToneIcon
      },
      {
        name: 'Recent Registrations',
        icon: RecentActorsTwoToneIcon,
        link: '/workers:showRecent'
      },
    ]
  },
  {
    heading: 'Reporting',
    items: [
      {
        name: 'Analytics',
        icon: AnalyticsTwoToneIcon,
        items: [
          {
            name: 'Registrations',
            link: '/analytics/registrations'
          },
        ],
      },
      {
        name: 'Questionnaires',
        icon: EmojiEventsTwoToneIcon,
        items: [
          {
            name: 'Registrations',
            link: '/questionnaires/resgistraions'
          },
        ],
      },
    ],
  },
  {
    heading: 'Administration',
    items: [
      {
        name: 'Manage Employees',
        icon: VerifiedUserTwoToneIcon,
        link: '/components/buttons'
      },
    ]
  },
];

export default menuItems;
