import { FC, useState, createContext } from 'react';

type Account = {
  forename: string;
  surname: string;
};

const nullAccount: Account = {
  forename: "Accept",
  surname: "Recruitment",
}

type UserContext = {
  getFullname: () => string;
  getAvatar: () => string;

  token: string | null;
  forename: string;
  surname: string;

  handlers: {
    store: (acc: Account, token: string) => void;
    forget: () => void;
  };
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserContext = createContext<UserContext>(
  {} as UserContext
);

export const UserProvider: FC = ({ children }) => {
  const [token, setToken] = useState<string|null>(null);
  const [account, setAccount] = useState<Account>(nullAccount);

  const getFullname = () => `${account.forename} ${account.surname}`;
  const getAvatar = () => `https://eu.ui-avatars.com/api/?background=random&name=${getFullname()}`;

  return (
    <UserContext.Provider value={{
      getFullname,
      getAvatar,

      token,
      forename: account?.forename,
      surname: account?.surname,

      handlers: {
        store: (acc: Account, token: string) => {
          setToken(token);
          setAccount(acc);
        },
        forget: () => {
          setToken(null);
          setAccount(nullAccount);
        },
      },
    }}>
      {children}
    </UserContext.Provider>
  );
};
